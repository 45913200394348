<template>
  <div
    style="width:100%;background: rgba(255,255,255,0.1);"
    class="rounded mt-sm-4"
  >
    <v-row>
      <v-col cols="10" sm="11" md="10" class="pb-0 pt-1  pt-sm-2 pr-0">
        <div class="d-flex">
          <v-item-group
            v-model="period_filter_selected_option"
            class="shrink ml-1 ml-sm-2 mr-0 mr-sm-6"
            mandatory
          >
            <v-item
              v-for="range in options"
              :key="range + '_extension_option'"
              v-slot:default="{ active, toggle }"
            >
              <div>
                <v-btn
                  text
                  block
                  rounded
                  :x-small="mobile"
                  :small="!mobile"
                  :input-value="active"
                  @click="toggle"
                  class="my-1"
                >
                  {{ getRange(range) }}
                </v-btn>
              </div>
            </v-item>
          </v-item-group>
          <div style="width:100%" class="ml-1 flex-grow-1">
            <v-window v-model="period_filter_selected_option" vertical>
              <v-window-item
                v-for="range in options"
                :key="range + '_extension_graph'"
              >
                <v-card
                  flat
                  light
                  class="px-lg-6 pb-1 pb-sm-2 transparent"
                  v-if="selected_option === 'month'"
                >
                  <v-tabs
                    class="mb-0 mb-sm-3"
                    :class="mobile ? 'text-body-2' : ''"
                    v-model="period_filter_selected_year"
                    background-color="transparent"
                    :height="mobile ? '18px' : '30px'"
                    centered
                    dark
                    small
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                      v-for="item in year_available_items"
                      :key="item"
                      :value="item"
                      :href="'#' + item"
                      :style="mobile ? 'font-size:10px;' : ''"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items
                    class="transparent pa"
                    v-model="period_filter_selected_year"
                  >
                    <v-tab-item
                      v-for="y in year_available_items"
                      :key="y"
                      :value="y.toString()"
                    >
                      <chart-bar
                        :height="mobile ? '30px' : '50px'"
                        :series="month_series"
                      ></chart-bar>
                      <div class="d-flex transparent mt-1">
                        <div
                          class="text-center"
                          v-for="m in 12"
                          :key="'month_' + m + '-' + y"
                          style="cursor: pointer;background:transparent; flex:1;"
                        >
                          <v-btn
                            dark
                            block
                            :small="!mobile"
                            :x-small="mobile"
                            :color="
                              selected_period == 'month' &&
                              selected_year === period_filter_selected_year &&
                              selected_month === m
                                ? 'primary'
                                : 'white'
                            "
                            :text="
                              (selected_period == 'month' &&
                                selected_year != period_filter_selected_year) ||
                                selected_month != m
                            "
                            @click="selectMonth(m)"
                            rounded
                            :disabled="!is_available_month(y, m)"
                          >
                            {{ getNameFromMonth(m) }}
                          </v-btn>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
                <v-card
                  flat
                  light
                  class="transparent"
                  v-if="selected_option === 'year'"
                >
                  <v-card-text class="py-0">
                    <chart-bar
                      :height="mobile ? '40px' : '80px'"
                      :series="year_series"
                    ></chart-bar>
                    <div class="d-flex justify-center my-1" style="width:100%;">
                      <div
                        class="text-center white--text"
                        style="flex:1;"
                        v-for="item in year_available_items"
                        :key="item"
                      >
                        <v-btn
                          small
                          :color="
                            selected_period === 'year' && selected_year === item
                              ? 'primary'
                              : 'white'
                          "
                          :text="
                            !(
                              selected_period === 'year' &&
                              selected_year === item
                            )
                          "
                          @click="selectYear(item)"
                          rounded
                        >
                          {{ item }}
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </div>
        </div>
      </v-col>
      <v-col
        cols="2"
        sm="1"
        md="2"
        class="pb-0 pt-0 pt-sm-2 justify-center align-center d-block"
      >
        <v-row v-if="!mobile">
          <v-col
            class="pr-10 pt-1 pb-0 amber--text accent-3 text-uppercase v-messages"
            cols="12"
          >
            <div class="v-messages__wrapper" v-if="!mobile">
              {{ $t("compared_to") }}:
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex" :class="{ 'd-block': mobile }">
          <v-col
            class="pr-0 pr-sm-10 pt-1 pb-0 amber--text accent-3 text-uppercase v-messages"
            cols="12"
            :class="{ 'd-flex': !mobile, 'd-block': mobile }"
          >
            <v-tooltip
              v-for="vs_option in vs_options"
              :key="'vs_option' + vs_option"
              content-class="text-center"
              bottom
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    :small="!mobile"
                    :x-small="mobile"
                    :style="
                      mobile
                        ? 'height:20px !important;width:20px !important;'
                        : ''
                    "
                    :outlined="vs_option != vs_filter_selected_option"
                    @click="
                      vs_filter_selected_option = vs_option;
                      vs_hotel_selected = null;
                    "
                    fab
                    color="amber accent-3"
                    class="mx-3 mx-sm-1 mb-1 mb-sm-0"
                  >
                    <v-icon small :x-small="mobile" v-if="vs_option == 'cs'"
                      >mdi-podium-gold</v-icon
                    >
                    <v-icon small :x-small="mobile" v-if="vs_option == 'bu'"
                      >mdi-trophy-outline</v-icon
                    >
                    <v-icon small :x-small="mobile" v-if="vs_option == 'ly'"
                      >mdi-reply-all-outline</v-icon
                    >
                    <v-icon small :x-small="mobile" v-if="vs_option == 'sply'"
                      >mdi-timetable</v-icon
                    >
                  </v-btn>
                </div>
              </template>
              <span v-html="$t(vs_option)"></span>
            </v-tooltip>
            <v-tooltip
              :key="'vs_option_other_hotel'"
              content-class="text-center"
              bottom
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    :key="'vs_option_hotel'"
                    :small="!mobile"
                    :x-small="mobile"
                    :style="
                      mobile
                        ? 'height:20px !important;width:20px !important;'
                        : ''
                    "
                    :outlined="vs_filter_selected_option != 'other_hotel'"
                    @click="openHotelSelector"
                    dark
                    light
                    fab
                    color="amber accent-3"
                    class="mx-3 mx-sm-1 mb-1 mb-sm-0"
                  >
                    <v-icon small :x-small="mobile"
                      >mdi-hospital-building</v-icon
                    >
                  </v-btn>
                </div>
              </template>
              <span v-html="$t('other_hotel')"></span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <a-hotel-selector
      v-if="show_hotel_vs_selector"
      @closeHotelSelector="closeHotelSelector"
      @setHotel="setHotel"
      :current_hotel_id="vs_hotel_selected"
    ></a-hotel-selector>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import chartBar from "@/components/chart/bar";
import aHotelSelector from "@/components/selector/aHotelSelector";

export default {
  components: {
    chartBar,
    aHotelSelector
  },
  props: {
    current_hotel_id: {
      default: 1
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show_hotel_vs_selector: false
  }),
  methods: {
    setHotel(hotel) {
      this.vs_hotel_selected = hotel.id;
      this.vs_filter_selected_option = "other_hotel";
    },
    openHotelSelector() {
      this.show_hotel_vs_selector = true;
    },
    closeHotelSelector() {
      this.show_hotel_vs_selector = false;
    },
    getRange(range) {
      return this.mobile ? this.$t(range).substring(0, 1) : this.$t(range);
    },
    getNameFromMonth(month_number) {
      var m = Vue.moment("2020-" + month_number + "-1").format("MMMM");
      return this.$vuetify.breakpoint.mdAndDown ? m.substring(0, 2) : m;
    },
    selectSelectorYear(year) {
      this.period_filter_selected_year = year;
    },
    selectMonth(month) {
      this.selected_period = "month";
      this.selected_month = month;
      this.selected_year = this.period_filter_selected_year;
    },
    selectYear(year) {
      this.selected_period = "year";
      this.selected_month = null;
      this.selected_year = year;
      this.period_filter_selected_year = year;
    },
    is_available_month(year, month) {
      // Search in available_months
      return (
        this.available_months[year] &&
        this.available_months[year].indexOf(parseInt(month)) >= 0
      );
    }
  },
  computed: {
    vs_text() {
      return this.vs_filter_selected_option == "other_hotel"
        ? this.getHotelById(this.vs_hotel_selected).name
        : this.$t(this.vs_filter_selected_option);
    },
    year_series() {
      var main_series = [];
      var vs_series = [];
      for (var y of this.available_years) {
        main_series.push(
          this.available_years_data[y] ? this.available_years_data[y] : 0
        );
        vs_series.push(
          this.available_years_data_vs[y] ? this.available_years_data_vs[y] : 0
        );
      }

      return [
        {
          type: "bar",
          values: main_series,
          backgroundColor: "#85d6e4",
          guideLabel: {
            padding: "3px 5px 0 5px",
            text:
              "<span style='font-size:11px;background-color:%color; color:white;border-radius:15px; padding: 1px 5px;'>%v</span> <span style='color: #666666;font-size:11px;font-weight:bold;'>" +
              this.$t("reviews") +
              " </span>",
            thousandsSeparator: ".",
            decimalsSeparator: ","
          }
        },
        {
          type: "bar",
          values: vs_series,
          backgroundColor: "#ffc94e",
          guideLabel: {
            padding: "0px 5px 3px 5px",
            text:
              "<span style='font-size:11px;background-color:%color; color:white;border-radius:15px; padding: 1px 5px;'>%v</span> <span style='color: #666666;font-size:11px;font-weight:bold;'>" +
              this.vs_text +
              "</span>",
            thousandsSeparator: ".",
            decimalsSeparator: ","
          }
        }
      ];
    },
    month_series() {
      var main_series = [];
      var vs_series = [];
      for (var x = 1; x <= 12; x++) {
        main_series.push(
          this.available_months_data[this.period_filter_selected_year] &&
            this.available_months_data[this.period_filter_selected_year][x]
            ? this.available_months_data[this.period_filter_selected_year][x]
            : 0
        );
        vs_series.push(
          this.available_months_data_vs[this.period_filter_selected_year] &&
            this.available_months_data_vs[this.period_filter_selected_year][x]
            ? this.available_months_data_vs[this.period_filter_selected_year][x]
            : 0
        );
      }
      return [
        {
          type: "bar",
          values: main_series,
          backgroundColor: "#85d6e4",
          guideLabel: {
            padding: "3px 5px 0 5px",
            text:
              "<span style='font-size:11px;background-color:%color; color:white;border-radius:15px; padding: 1px 5px;'>%v</span> <span style='color: #666666;font-size:11px;font-weight:bold;'>" +
              this.$t("reviews") +
              " </span>",
            thousandsSeparator: ".",
            decimalsSeparator: ","
          }
        },
        {
          type: "bar",
          values: vs_series,
          backgroundColor: "#ffc94e",
          guideLabel: {
            padding: "0px 5px 3px 5px",
            text:
              "<span style='font-size:11px;background-color:%color; color:white;border-radius:15px; padding: 1px 5px;'>%v</span> <span style='color: #666666;font-size:11px;font-weight:bold;'>" +
              this.vs_text +
              "</span>",
            thousandsSeparator: ".",
            decimalsSeparator: ","
          }
        }
      ];
    },
    selected_option() {
      return this.options[this.period_filter_selected_option];
    },
    year_available_items() {
      var items = [];
      items = this.available_years;
      return items;
    },
    month_available_items() {
      var items = [];
      for (var x = 1; x <= 12; x++) {
        items.push(x);
      }
      return items;
    },
    selected_period: {
      get() {
        return this.$store.state.selected_period;
      },
      set(value) {
        this.$store.dispatch("setSelectedPeriod", value);
      }
    },
    selected_year: {
      get() {
        return this.$store.state.selected_year;
      },
      set(value) {
        this.$store.dispatch("setSelectedYear", value);
      }
    },
    selected_month: {
      get() {
        return this.$store.state.selected_month;
      },
      set(value) {
        this.$store.dispatch("setSelectedMonth", value);
      }
    },
    menu_extended: {
      get() {
        return this.$store.state.menu_extended;
      },
      set(value) {
        this.$store.dispatch("setMenuExtended", value);
      }
    },
    period_filter_selected_option: {
      get() {
        return this.$store.state.period_filter_selected_option;
      },
      set(value) {
        this.$store.dispatch("setPeriodFilterSelectedOption", value);
      }
    },
    period_filter_selected_year: {
      get() {
        return this.$store.state.period_filter_selected_year;
      },
      set(value) {
        this.$store.dispatch("setPeriodFilterSelectedYear", value);
      }
    },
    vs_filter_selected_option: {
      get() {
        return this.$store.state.vs_filter_selected_option;
      },
      set(value) {
        this.$store.dispatch("setVsFilterSelectedOption", value);
      }
    },
    vs_hotel_selected: {
      get() {
        return this.$store.state.vs_hotel_selected;
      },
      set(value) {
        this.$store.dispatch("setVsHotelSelected", value);
      }
    },
    ...mapState({
      options: state => state.options,
      vs_options: state => state.vs_options,
      available_months: state => state.hotel.hotel_available_months,
      available_months_data: state => state.hotel.hotel_available_months_data,
      available_years_data: state => state.hotel.hotel_available_years_data,
      available_months_data_vs: state =>
        state.hotel.hotel_available_months_data_vs,
      available_years_data_vs: state =>
        state.hotel.hotel_available_years_data_vs
    }),
    ...mapGetters({
      available_years: "getHotelAvailableYears",
      current_period_items: "current_period_items",
      vs_period_items: "vs_period_items",
      getHotelById: "getHotelById"
    })
  }
};
</script>
<style>
.a-selector {
  height: 48px;
  padding: 5px;
  right: 0px;
  overflow: hidden;
  z-index: 2;
  border-radius: 30px;
  margin-top: 5px;
  border: 2px solid #fff;
  background: transparent;
  box-shadow: 0 0 14px -2px #333;
}
</style>
