var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-base-component',{attrs:{"subscribed_modules":_vm.subscribed_modules},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.current_hotel)?_c('v-tooltip',{attrs:{"bottom":"","dark":"","color":"#333333"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","outlined":"","rounded":""},on:{"click":_vm.goToImportStatus}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(_vm._f("formatDatetime")(_vm.current_hotel.last_import_date.dashboard))+" ")],1)]}}],null,false,330148482)},[_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("last_update"))+" ")])],1),_vm._l((_vm.slots),function(slot){return _c('v-row',{key:slot['name'],attrs:{"dense":""}},[_c('v-col',{staticClass:"align-self-center"},[(
                _vm.dates[slot['name']]['today'] !==
                  _vm.dates[slot['name']]['import_date']
              )?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.importStatusColor(_vm.dates[slot['name']])}},[_vm._v("mdi-clock-alert-outline")]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.importStatusColor(_vm.dates[slot['name']])}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(slot["label"])+" ")],1),_c('v-col',{staticClass:"text-right text-no-wrap align-self-center import-dates"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.dates[slot["name"]]["import_date"]))+" "),(
                _vm.dates[slot['name']]['today'] !==
                  _vm.dates[slot['name']]['import_date']
              )?_c('span',{staticClass:"text-decoration-line-through text-no-wrap"},[_c('br'),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.dates[slot["name"]]["today"])))]):_vm._e()])],1)}),(_vm.production_mode)?_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},[_c('v-col',[_c('v-divider',{staticClass:"my-3",attrs:{"dark":""}}),_vm._v(" "+_vm._s(_vm.$t("production_mode_" + _vm.production_mode))+" ")],1)],1):_vm._e()],2):_vm._e()]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"chip"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }