<template>
  <v-app-bar
    fixed
    app
    dark
    elevateOnScroll
    :extended="main_filters && menu_extended"
    :extension-height="extension_height"
    :style="bg_style"
    v-scroll="onScroll"
  >
    <v-toolbar-title class="ml-0 d-flex align-center">
      <v-app-bar-nav-icon
        class=""
        v-if="$vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        class=""
        v-else
        @click.stop="mini = !mini"
      ></v-app-bar-nav-icon>
      <span class="title text-uppercase ml-1"
        >{{ real_title }}
        <span
          class="subtitle-2 grey--text text--lighten-2 ml-1"
          v-if="$route.meta.subtitle && $vuetify.breakpoint.mdAndUp"
        >
          {{ $t($route.meta.subtitle) }}</span
        ></span
      >
      <slot name="share"> </slot>
    </v-toolbar-title>
    <div class="flex-grow-1"></div>
    <!-- Selector -->
    <slot name="options">
      <a-selector v-if="main_filters"></a-selector>
    </slot>

    <template v-slot:extension v-if="main_filters && menu_extended">
      <a-selector-extension></a-selector-extension>
    </template>
  </v-app-bar>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import aSelector from "@/components/aSelector";
import aSelectorExtension from "@/components/aSelectorExtension";

export default {
  props: {
    image_source: String,
    main_filters: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },
  components: {
    aSelector,
    aSelectorExtension
  },
  data: () => ({
    offsetTop: 0,
    extension_height: 160
  }),
  methods: {
    showHotelList() {
      this.show_hotel_list = true;
    },
    onScroll(e) {
      this.offsetTop = window.scrollY;
    }
  },
  computed: {
    real_title() {
      return this.title ? this.title : this.$t(this.$route.meta.title);
    },
    bg_style() {
      return this.offsetTop > 0
        ? "background-image: url(" +
            this.image_source +
            ") !important; background-position: center top; background-size: cover;"
        : "background: none !important;";
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      }
    },
    mini: {
      get() {
        return this.$store.state.mini;
      },
      set(value) {
        this.$store.dispatch("setMini", value);
      }
    },
    show_hotel_list: {
      get() {
        return this.$store.state.hotel.show_hotel_list;
      },
      set(value) {
        this.$store.dispatch("setShowHotelList", value);
      }
    },
    menu_extended: {
      get() {
        return this.$store.state.menu_extended;
      },
      set(value) {
        this.$store.dispatch("setMenuExtended", value);
      }
    },
    ...mapState({
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style>
.v-toolbar__extension {
  padding-bottom: 10px;
}
header.v-app-bar--is-scrolled {
  background: transparent !important;
  color: #333333;
  height: auto !important;
}
</style>
