<template>
  <v-app-bar
    fixed
    elevate-on-scroll
    app
    dark
    class="px-0"
    :style="bg_style"
    v-scroll="onScroll"
    :extension-height="90"
    height="45"
  >
    <v-list dense style="background:none;">
      <v-list-item @click="showHotelList" class="menu_first_node no-click px-0">
        <!-- CHAIN LOGO -->
        <v-list-item-avatar
          :size="30"
          v-if="
            current_hotel &&
              current_hotel.settings &&
              current_hotel.settings.is_chain
          "
        >
          <v-img :src="chain.logo"></v-img>
        </v-list-item-avatar>
        <!-- HOTEL LOGO -->
        <v-btn
          :size="30"
          fab
          small
          depressed
          v-else-if="current_hotel && current_hotel.settings"
          :color="current_hotel.settings.color"
          class="v-list-item__avatar subtitle-2 white--text text-uppercase justify-center"
          >{{ current_hotel.settings.abbreviation }}
        </v-btn>
      </v-list-item>
    </v-list>
    <div class="flex-grow-1"></div>
    <!-- Selector -->
    <slot name="options">
      <a-selector v-if="main_filters" mobile></a-selector>
    </slot>

    <template v-slot:extension v-if="main_filters && menu_extended">
      <a-selector-extension mobile></a-selector-extension>
    </template>
  </v-app-bar>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import aSelector from "@/components/aSelector";
import aSelectorExtension from "@/components/aSelectorExtension";

export default {
  props: {
    image_source: String,
    main_filters: {
      type: Boolean,
      default: false
    }
  },
  components: {
    aSelector,
    aSelectorExtension
  },
  data: () => ({
    offsetTop: 0,
    extension_height: 160
  }),
  methods: {
    showHotelList() {
      this.show_hotel_list = true;
    },
    onScroll(e) {
      this.offsetTop = window.scrollY;
    }
  },
  computed: {
    real_title() {
      return this.title ? this.title : this.$t(this.$route.meta.title);
    },
    bg_style() {
      //return "background-image: url(" + this.image_source + ") !important;";
      return this.offsetTop > 0
        ? "background: url(" + this.image_source + ") top  !important;"
        : "background: none !important;";
    },
    menu_extended: {
      get() {
        return this.$store.state.menu_extended;
      },
      set(value) {
        this.$store.dispatch("setMenuExtended", value);
      }
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      }
    },
    mini: {
      get() {
        return this.$store.state.mini;
      },
      set(value) {
        this.$store.dispatch("setMini", value);
      }
    },
    show_hotel_list: {
      get() {
        return this.$store.state.hotel.show_hotel_list;
      },
      set(value) {
        this.$store.dispatch("setShowHotelList", value);
      }
    },
    ...mapState({
      chain: state => state.chain.chain
      //current_hotel: state => state.hotel.hotel,
      // snackbar: state => state.snackbar,
      // users: state => state.user.users,
      // user: state => state.user.user,
      // language_options: state => state.language.language_options
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
      // getUserById: "getUserById",
      // current_user: "current_user",
      // dialogHeight: "dialogHeight",
      // authenticated: "authenticated"
    })
  }
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .v-app-bar--is-scrolled {
    background: white !important;
    /* height: 45px !important; */
  }
  .v-app-bar--is-scrolled .v-list-item__title {
    color: #333333;
  }
  .v-app-bar--is-scrolled .v-btn--fab.v-size--small {
    width: 30px;
    height: 30px;
  }
  .v-btn--fab.v-size--x-small {
    width: 17px;
    height: 17px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0 5px !important;
  }
}
</style>
