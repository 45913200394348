<template>
  <div>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-tooltip bottom dark color="#333333" v-if="current_hotel">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="white"
              outlined
              @click="goToImportStatus"
              rounded
            >
              <v-icon small class="mr-1">mdi-clock-outline</v-icon>
              {{ current_hotel.last_import_date.dashboard | formatDatetime }}
            </v-btn>
          </template>
          <v-row dense class="text-center">
            <v-col>
              {{ $t("last_update") }}
            </v-col>
          </v-row>
          <v-row dense v-for="slot in slots" v-bind:key="slot['name']">
            <v-col class="align-self-center">
              <v-icon
                small
                class="mr-1"
                :color="importStatusColor(dates[slot['name']])"
                v-if="
                  dates[slot['name']]['today'] !==
                    dates[slot['name']]['import_date']
                "
                >mdi-clock-alert-outline</v-icon
              >
              <v-icon
                small
                class="mr-1"
                :color="importStatusColor(dates[slot['name']])"
                v-else
                >mdi-check</v-icon
              >
              {{ slot["label"] }}
            </v-col>
            <v-col
              class="text-right text-no-wrap align-self-center import-dates"
            >
              {{ dates[slot["name"]]["import_date"] | formatDate }}
              <span
                class="text-decoration-line-through text-no-wrap"
                v-if="
                  dates[slot['name']]['today'] !==
                    dates[slot['name']]['import_date']
                "
                ><br />
                {{ dates[slot["name"]]["today"] | formatDate }}</span
              >
            </v-col>
          </v-row>
          <v-row dense class="text-center" v-if="production_mode">
            <v-col>
              <v-divider dark class="my-3"></v-divider>
              {{ $t("production_mode_" + production_mode) }}
            </v-col>
          </v-row>
        </v-tooltip>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import ABaseComponent from "@/components/aBaseComponent.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "a-rms-import-dates",
  components: { ABaseComponent },
  props: {},
  data: () => ({
    subscribed_modules: ["dataimport_revenue_dates"],
    slots: [
      {
        name: "otb",
        label: "OTB"
      },
      {
        name: "stly",
        label: "STLY"
      },
      {
        name: "ly",
        label: "LY"
      }
    ]
  }),
  computed: {
    dates: function() {
      return this.revenue_dates.dates;
    },
    can_access_settings() {
      return (
        this.current_hotel.permissions.revenue_import_manual &&
        this.current_user.permissions.revenue_import_manual
      );
    },
    production_mode() {
      return this.current_hotel.settings &&
        this.current_hotel.settings.production_mode
        ? this.current_hotel.settings.production_mode
        : null;
    },
    ...mapState({
      revenue_dates: state => state.dataimport.revenue_dates
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  methods: {
    importStatusColor: function(status) {
      if (status["today"] === status["import_date"]) {
        return "green";
      } else if (status["import_date"] !== null) {
        return "yellow darken-1";
      } else {
        return "yellow darken-1";
      }
    },
    goToImportStatus: function() {
      if (this.can_access_settings) {
        this.$router.push({
          name: "revenue-import"
        });
      }
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped>
.import-dates {
  line-height: 15px;
}
</style>
