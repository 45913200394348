<template>
  <v-card
    :id="a_card_id"
    :elevation="elevation"
    :height="real_height"
    :width="real_width"
    class="a-card"
  >
    <a id="image_to_download"></a>
    <v-toolbar dense flat v-if="!no_title" :color="header_color">
      <v-toolbar-title
        class="font-weight-bold text-uppercase subtitle-2 mr-2 d-flex align-center"
      >
        <slot name="pretitle"> </slot>
        <span v-html="title"></span>
      </v-toolbar-title>
      <slot name="subtitle"> </slot>
      <v-spacer></v-spacer>
      <slot name="options"> </slot>
    </v-toolbar>
    <v-card-text class="pt-1 pb-3 px-1 px-md-4" :class="clas">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import * as Utils from "@/utils.js";

export default {
  name: "a-card",
  props: {
    clas: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    no_title: {
      type: Boolean,
      default: false
    },
    hide_loading: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    },
    subscribed_modules: {
      type: Array,
      default: () => []
    },
    header_color: {
      type: String,
      default: "white"
    }
  },
  data() {
    return {
      a_card_id: "",
      // fullscreen: false,
      // optimized: true,
      available_pencil_color: [
        {
          code: "orange",
          color: "orange"
        },
        {
          code: "red",
          color: "red"
        },
        {
          code: "blue",
          color: "blue"
        },
        {
          code: "green",
          color: "green"
        },
        {
          code: "black",
          color: "black"
        }
      ],
      pencil_color: "red",
      available_pencil_width: [4, 8, 15, 20],
      pencil_width: 5
    };
  },
  created() {
    this.a_card_id = "a-card-" + Math.floor(Math.random() * (100000 - 1) + 1);
  },
  methods: {
    download() {
      Utils.hackSvg();
      var vm = this;
      this.$html2canvas(document.getElementById(this.a_card_id), {
        allowTaint: true, //you can add this property
        foreignObjectRendering: true,
        useCORS: true
      }).then(canvas => {
        var a = document.getElementById("image_to_download");
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL("image/png");
        a.download = "image.png";
        a.click();
      });
    },
    share() {
      Utils.hackSvg();
      this.loading_screenshot = true;
      this.share_content = true;
      var vm = this;
      this.$html2canvas(document.getElementById(this.a_card_id), {
        allowTaint: true, //you can add this property
        foreignObjectRendering: true,
        useCORS: true
      }).then(canvas => {
        vm.share_image_src = canvas.toDataURL();
        vm.share_image_width = document.getElementById(
          vm.a_card_id
        ).offsetWidth;
        vm.share_image_height = document.getElementById(
          vm.a_card_id
        ).offsetHeight;
        // if (vm.fullscreen) {
        //   vm.toggle();
        // }
      });
    },
    changePencilWidth(width) {
      this.canvas.freeDrawingBrush.width = parseInt(width, 10) || 1;
    },
    changePencilColor(color) {
      var brush = this.canvas.freeDrawingBrush;
      brush.color = color;
      if (brush.getPatternSrc) {
        brush.source = brush.getPatternSrc.call(brush);
      }
    },
    clear() {
      this.canvas.clear();
    }
  },
  computed: {
    loading_screenshot: {
      get() {
        return this.$store.state.loading_screenshot;
      },
      set(value) {
        this.$store.dispatch("setLoadingScreenshot", value);
      }
    },
    share_image_src: {
      get() {
        return this.$store.state.share_image_src;
      },
      set(value) {
        this.$store.dispatch("setShareImageSrc", value);
      }
    },
    share_image_width: {
      get() {
        return this.$store.state.share_image_width;
      },
      set(value) {
        this.$store.dispatch("setShareImageWidth", value);
      }
    },
    share_image_height: {
      get() {
        return this.$store.state.share_image_height;
      },
      set(value) {
        this.$store.dispatch("setShareImageHeight", value);
      }
    },
    share_content: {
      get() {
        return this.$store.state.share_content;
      },
      set(value) {
        this.$store.dispatch("setShareContent", value);
      }
    },
    real_height() {
      return "100% !important";
    },
    real_width() {
      return "100% !important";
    }
  },
  watch: {
    pencil_color() {
      this.changePencilColor(this.pencil_color);
    },
    pencil_width() {
      this.changePencilWidth(this.pencil_width);
    }
  }
};
</script>
<style scoped>
.fullscreened-card {
  margin: 100px auto 20px auto;
}
.v-card {
  overflow: hidden;
}
</style>
