<template>
  <div>
    <slot name="not-loaded" v-if="status == 0">
      <v-alert color="orange" text type="warning">{{
        $t("not_loaded")
      }}</v-alert>
    </slot>
    <slot name="loading" v-else-if="status == 1">
      <v-skeleton-loader type="sentences"></v-skeleton-loader>
    </slot>
    <slot v-else-if="status == 2"> </slot>
  </div>
</template>
<script>
import Vue from "vue";
import * as Utils from "@/utils.js";

export default {
  name: "a-base-component",
  props: {
    subscribed_modules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  created() {
    this.id =
      "a-base-component-" + Math.floor(Math.random() * (100000 - 1) + 1);
  },
  computed: {
    status() {
      return Utils.status(this);
    }
  },
  watch: {
    status() {
      if (this.status === 2) {
        this.$emit("loaded");
      }
    }
  }
};
</script>
