<template>
  <!-- RATE DATE TO COMPARE -->
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="model"
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline justify-center text-capitalize">
        {{ $t("select_hotel") }}
        <v-spacer></v-spacer>
        <v-btn
          style="position:absolute; top:13px; right:10px;"
          icon
          @click="closeHotelSelector"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-list dense id="hotels-resume-app">
          <a-hotel-list-item
            v-for="hotel in first_level_hotels"
            :hotel="hotel"
            :level="0"
            :currencies="currencies"
            :key="hotel.id"
            :current_hotel_id="current_hotel_id"
            @setHotel="setHotel"
          >
          </a-hotel-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aHotelListItem from "@/components/selector/aHotelListItem";

export default {
  name: "a-hotel-selector",
  props: ["current_hotel_id"],
  components: {
    aHotelListItem
  },
  data: () => ({
    model: true
  }),
  methods: {
    setHotel(hotel) {
      this.$emit("setHotel", hotel);
      this.$emit("closeHotelSelector");
    },
    closeHotelSelector() {
      this.$emit("closeHotelSelector");
    }
  },
  watch: {},
  computed: {
    userHotels() {
      return this.hotels;
    },
    first_level_hotels() {
      return this.hotels.filter(hotel => hotel.first_level == true);
    },

    ...mapState({
      hotels: state => state.hotel.hotels,
      currencies: state => state.currency.currencies,
      hotel_selected: state => state.hotel.hotel
    }),
    ...mapGetters({})
  },
  created() {}
};
</script>
