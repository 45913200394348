<template>
  <div
    class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
    style="position:relative"
  >
    <div class="px-10 mx-5">
      <h3 class="text-h4 d-block">
        {{ $t("must_select_hotel") }}
      </h3>
      <h6 class="text-h6 d-block mt-3 mb-6">
        {{ $t("this_report_is_just_for_hotels") }}
      </h6>
      <v-btn
        class="mt-12"
        color="primary"
        rounded
        outlined
        @click="open_hotel_selector"
        >{{ $t("choose_hotel") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "a-hotel-selector-rms",
  methods: {
    open_hotel_selector: function() {
      this.$store.dispatch("setShowHotelList", true);
    }
  }
};
</script>
