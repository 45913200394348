<template>
  <div class="mt-1" style="position: relative;">
    <v-btn
      v-if="isGroup && !hotel.first_level"
      fab
      absolute
      x-small
      color="grey lighten-2"
      style="left:-20px; top: 3px;"
      :class="{ 'rotate-right': !opened }"
      @click="opened = !opened"
      ><v-icon>mdi-chevron-down</v-icon></v-btn
    >
    <!-- <v-hover v-slot:default="{ hover }"> -->
    <v-card
      @click.stop="setHotel(hotel)"
      dense
      v-blur
      :disabled="current_hotel.id == hotel.id"
      class="hotel-component"
      style="cursor: pointer;"
      :color="isActive ? 'orange' : ''"
    >
      <v-list dense class="transparent py-0">
        <v-list-item
          :class="{ 'no-click': current_hotel.id == hotel.id || isActive }"
          :style="isGroup && !hotel.first_level ? 'padding-left: 35px;' : ''"
        >
          <v-list-item-avatar
            size="25"
            v-if="hotel.first_level"
            style="margin-top:6px; margin-bottom: 6px !important;"
          >
            <v-img :src="chain.logo"></v-img>
          </v-list-item-avatar>
          <template v-else>
            <v-btn
              v-if="!isActive"
              fab
              :color="hotel.settings.color"
              class="v-list-item__avatar subtitle-2 white--text text-uppercase justify-center "
              style="height: 25px; min-width: 25px; width: 25px !important;margin-top:6px; margin-bottom: 6px !important;"
              @click="setHotel(hotel)"
              :elevation="isLeaf ? 0 : 5"
              >{{ hotel.settings.abbreviation }}
            </v-btn>
            <v-btn
              fab
              v-else
              color="info"
              class="v-list-item__avatar title white--text text-uppercase justify-center"
              style="height: 25px; min-width: 25px; width: 25px !important;margin-top:6px; margin-bottom:6px !important;"
              ><v-icon color="white" small>mdi-check</v-icon>
            </v-btn>
          </template>
          <v-list-item-content class=" py-2 ">
            <v-list-item-title
              class="subtitle-2"
              :class="{ 'text-uppercase': isGroup }"
              v-text="hotel.name"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-rating
              v-if="isLeaf"
              color="primary"
              readonly
              dense
              small
              class="mt-n1"
              :value="hotel.settings.stars"
            ></v-rating>
            <div
              v-else-if="hotel.first_level"
              class="v-list-item_title grey--text subtitle-2 mt-n1"
              :class="{ 'text-uppercase': isGroup }"
            >
              {{ $t("chain") }}
            </div>
            <div
              v-else
              class="v-list-item__title grey--text subtitle-2 mt-n1"
              :class="{ 'text-uppercase': isGroup }"
            >
              {{ $t("group") }}
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <!-- </v-hover> -->
    <v-expand-transition>
      <div
        v-show="child_hotels.length > 0 && opened"
        class="mx-6 mb-4 grey lighten-4"
      >
        <a-hotel-list-item
          v-for="h in child_hotels"
          :hotel="h"
          :level="level + 1"
          :key="h.id"
          @setHotel="setHotel"
          :current_hotel_id="current_hotel_id"
        >
        </a-hotel-list-item>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
// import aasKpi from "@/components/aasKpi";

export default {
  components: {
    // aasKpi
  },
  name: "a-hotel-list-item",
  data: () => ({
    opened: true
  }),
  props: ["hotel", "level", "current_hotel_id"],
  methods: {
    setHotel(hotel) {
      this.$emit("setHotel", hotel);
    }
  },
  watch: {},
  computed: {
    isLeaf() {
      return this.hotel.children_number == 0;
    },
    isGroup() {
      return this.hotel.children_number > 0;
    },
    isActive() {
      return this.current_hotel_id == this.hotel.id;
    },
    kpis() {
      return this.hotel.resume_data ? Object.keys(this.hotel.resume_data) : [];
    },
    child_hotels() {
      return this.hotels.filter(h => h.father_id == this.hotel.id);
    },
    show_hotel_list: {
      get() {
        return this.$store.state.hotel.show_hotel_list;
      },
      set(value) {
        this.$store.dispatch("setShowHotelList", value);
      }
    },
    ...mapState({
      chain: state => state.chain.chain,
      hotels: state => state.hotel.hotels
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  created() {}
};
</script>
<style scoped>
.rotate-right .v-icon {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  display: inline-block;
}
</style>
