<template>
  <div id="a-selector" :class="{ 'mobile-selector': mobile }">
    <!-- NEXT/PREV PERIOD -->
    <v-btn
      v-if="!mobile"
      class=""
      small
      text
      fab
      @click="substract"
      :disabled="!can_substract"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      v-if="!mobile"
      class="mr-1"
      small
      text
      fab
      @click="add"
      :disabled="!can_add"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <!-- (end) NEXT/PREV PERIOD -->

    <!-- OTB / YTD/ MTD -->
    <!-- <v-menu offset-y v-if="is_current_period">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mr-1"
          :small="!mobile"
          :x-small="mobile"
          text
          fab
        >
          <v-icon
            v-if="otb_option == 'otb'"
            v-text="'mdi-circle-slice-8'"
          ></v-icon>
          <v-icon
            v-if="otb_option == 'ytd'"
            v-text="'mdi-circle-slice-' + slice"
          ></v-icon>
          <v-icon
            v-if="otb_option == 'mtd'"
            v-text="'mdi-circle-slice-' + slice"
          ></v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader v-if="selected_period == 'month'">{{
          $t("this_month")
        }}</v-subheader>
        <v-subheader v-else>{{ $t("this_year") }}</v-subheader>
        <v-list-item-group mandatory v-model="otb_option" color="secondary">
          <v-list-item
            key="otb"
            value="otb"
            class="pl-1"
            v-if="selected_period == 'month'"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-circle-slice-8'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("until_end_of_month")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="otb"
            value="otb"
            class="pl-1"
            v-if="selected_period == 'year'"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-circle-slice-8'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("until_end_of_year")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            key="mtd"
            value="mtd"
            v-if="selected_period == 'month'"
            class="pl-1"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-circle-slice-' + slice"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >{{ $t("until_yesterday") }}, {{ yesterday }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="ytd"
            value="ytd"
            v-if="selected_period == 'year'"
            class="pl-1"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-circle-slice-' + slice"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("until_last_closed_month")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu> -->
    <!-- (end) OTB / YTD/ MTD -->
    <!-- SHOW HIDE EXTENDED -->
    <v-btn
      :small="mobile"
      light
      rounded
      outlined
      color="white"
      class="font-weight-medium elevation-0"
      @click="menu_extended = !menu_extended"
    >
      <span>{{ currentReportPeriod }}</span>
      <v-icon class="mx-3">mdi-compare-horizontal</v-icon>
      <span v-if="mobile" class="amber--text accent-3">{{
        vs_filter_selected_option
      }}</span>
      <span v-else class="amber--text accent-3">
        {{ vs_text }}
      </span>
    </v-btn>
    <v-btn
      small
      text
      fab
      light
      class="ml-2 elevation-0"
      color="white"
      v-if="menu_extended && !mobile"
      @click="menu_extended = !menu_extended"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    <!-- (end) SHOW HIDE EXTENDED -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-selector",
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  methods: {
    add() {
      switch (this.selected_period) {
        case "month":
          var date = Vue.moment(
            this.selected_year + "-" + this.selected_month + "-01"
          )
            .startOf("month")
            .add(1, "month");
          this.selected_month = parseInt(date.format("M"));
          this.selected_year = date.format("YYYY");
          this.period_filter_selected_year = date.format("YYYY");
          break;
        case "year":
          this.selected_year = (parseInt(this.selected_year) + 1).toString();
          break;
      }
    },
    substract() {
      switch (this.selected_period) {
        case "month":
          var date = Vue.moment(
            this.selected_year + "-" + this.selected_month + "-01"
          )
            .startOf("month")
            .subtract(1, "month");
          this.selected_month = parseInt(date.format("M"));
          this.selected_year = date.format("YYYY");
          this.period_filter_selected_year = date.format("YYYY");
          break;
        case "year":
          this.selected_year = (parseInt(this.selected_year) - 1).toString();
          break;
      }
    }
  },
  computed: {
    vs_text() {
      return this.vs_filter_selected_option == "other_hotel"
        ? this.getHotelById(this.vs_hotel_selected).name
        : this.$t(this.vs_filter_selected_option);
    },
    today() {
      return Vue.moment().format("D MMMM");
    },
    yesterday() {
      return Vue.moment()
        .subtract(1, "days")
        .format("D MMMM");
    },
    slice() {
      var slice = 1;
      if (this.selected_period === "month") {
        slice = parseInt((parseInt(Vue.moment().format("D")) + 3) / 4);
      } else {
        slice = parseInt((parseInt(Vue.moment().format("M")) + 3) / 2);
      }
      if (slice > 7) {
        slice = 7;
      }
      return slice;
    },
    can_add() {
      // Search in available_months
      var result = false;
      switch (this.selected_period) {
        case "month":
          var date = Vue.moment(
            this.selected_year + "-" + this.selected_month + "-01"
          )
            .startOf("month")
            .add(1, "month");
          result =
            this.available_months[date.format("YYYY")] &&
            this.available_months[date.format("YYYY")].indexOf(
              parseInt(date.format("M"))
            ) >= 0;
          break;
        case "year":
          result = this.available_months[
            (parseInt(this.selected_year) + 1).toString()
          ];
          break;
      }
      return result;
    },
    can_substract() {
      // Search in available_months
      var result = false;
      switch (this.selected_period) {
        case "month":
          var date = Vue.moment(
            this.selected_year + "-" + this.selected_month + "-01"
          )
            .startOf("month")
            .subtract(1, "month");
          result =
            this.available_months[date.format("YYYY")] &&
            this.available_months[date.format("YYYY")].indexOf(
              parseInt(date.format("M"))
            ) >= 0;
          break;
        case "year":
          result = this.available_months[
            (parseInt(this.selected_year) - 1).toString()
          ];
          break;
      }
      return result;
    },
    menu_extended: {
      get() {
        return this.$store.state.menu_extended;
      },
      set(value) {
        this.$store.dispatch("setMenuExtended", value);
      }
    },
    vs_filter_selected_option: {
      get() {
        return this.$store.state.vs_filter_selected_option;
      },
      set(value) {
        this.$store.dispatch("setVsFilterSelectedOption", value);
      }
    },
    selected_period: {
      get() {
        return this.$store.state.selected_period;
      },
      set(value) {
        this.$store.dispatch("setSelectedPeriod", value);
      }
    },
    selected_year: {
      get() {
        return this.$store.state.selected_year;
      },
      set(value) {
        this.$store.dispatch("setSelectedYear", value);
      }
    },
    selected_month: {
      get() {
        return this.$store.state.selected_month;
      },
      set(value) {
        this.$store.dispatch("setSelectedMonth", value);
      }
    },
    period_filter_selected_year: {
      get() {
        return this.$store.state.period_filter_selected_year;
      },
      set(value) {
        this.$store.dispatch("setPeriodFilterSelectedYear", value);
      }
    },
    otb_option: {
      get() {
        return this.$store.state.otb_option;
      },
      set(value) {
        this.$store.dispatch("setOtbOption", value);
      }
    },
    currentReportPeriod() {
      var from = "";
      var to = "";
      var format = "D MMM YYYY";
      switch (this.selected_period) {
        case "month":
          // From first of month
          from = Vue.moment(
            this.selected_year + "-" + this.selected_month + "-01"
          ).format(format);
          // To:
          //  - mtd : today
          //  - otb : end fo month
          to = Vue.moment(
            this.selected_year + "-" + this.selected_month + "-01"
          )
            .endOf("month")
            .format(format);
          if (this.otb_option == "mtd" && this.is_current_period) {
            to = Vue.moment()
              .subtract(1, "days")
              .format(format);
          }
          break;
        case "year":
          // From first of year to end of year
          from = Vue.moment(this.selected_year + "-01-01").format(format);
          // To:
          //  - ytd : last closed month
          //  - otb : end of year
          to = Vue.moment(this.selected_year + "-12-31")
            .endOf("year")
            .format(format);
          if (this.otb_option == "ytd" && this.is_current_period) {
            to = Vue.moment()
              .subtract(1, "month")
              .endOf("month")
              .format(format);
          }

          break;
      }
      return from + " - " + to;
    },
    ...mapState({
      available_months: state => state.hotel.hotel_available_months,
      vs_hotel_selected: state => state.vs_hotel_selected
    }),
    ...mapGetters({
      available_years: "getHotelAvailableYears",
      is_current_period: "is_current_period",
      getHotelById: "getHotelById"
    })
  }
};
</script>
<style>
.aas-selector {
  height: 48px;
  padding: 5px;
  right: 0px;
  overflow: hidden;
  z-index: 2;
  border-radius: 30px;
  margin-top: 5px;
  border: 2px solid #fff;
  background: transparent;
  box-shadow: 0 0 14px -2px #333;
}
</style>
