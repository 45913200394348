import { render, staticRenderFns } from "./aCard.vue?vue&type=template&id=52b7a340&scoped=true"
import script from "./aCard.vue?vue&type=script&lang=js"
export * from "./aCard.vue?vue&type=script&lang=js"
import style0 from "./aCard.vue?vue&type=style&index=0&id=52b7a340&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b7a340",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VSpacer,VToolbar,VToolbarTitle})
