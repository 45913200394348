<template>
  <div id="chart-bar" class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "chart-bar",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Array,
      default: function() {
        return [
          {
            values: [15],
            backgroundColor: "#85d6e4",
            borderRadiusTopLeft: 7,
            borderRadiusTopRight: 7
          },
          {
            values: [20],
            backgroundColor: "#ffc94e",
            borderRadiusTopLeft: 7,
            borderRadiusTopRight: 7
          }
        ];
      }
    }
  },
  data: vm => ({}),
  methods: {},
  computed: {
    graph_data() {
      var tooltip = this.chart_settings.tooltip;
      tooltip.lineHeight = "16px";
      tooltip.fontSize = "16px";
      return {
        type: "mixed",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "0px 0px 0px 0px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          visible: false
        },
        scaleY: {
          visible: false
        },
        crosshairX: {
          plotLabel: tooltip,
          lineColor: "#cacaca",
          lineWidth: 2,
          marker: {
            backgroundColor: "#ffffff",
            size: 4,
            borderWidth: 2,
            opacity: "0.8",
            shadow: 2
          }
        }
      };
    },
    graph_series() {
      return this.series;
    },
    date_hover: {
      get() {
        return this.$store.state.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    ...mapState({
      chart_settings: state => state.chart_settings
    }),
    ...mapGetters({})
  }
};
</script>
