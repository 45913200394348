<template>
  <a-base-component>
    <template v-slot:default>
      <v-row>
        <v-col cols="12" md="12" class="d-flex justify-center">
          <v-btn
            outlined
            rounded
            color="white"
            @click="showRangeSelector"
            class="px-6 elevation-0"
          >
            <v-icon small class="mr-2">mdi-calendar-filter-outline</v-icon>
            <span class="" style="text-transform: none;">
              {{ formatedEventPeriod }}
            </span>
          </v-btn>
          <div
            v-if="isFullMonthOutside"
            class="d-flex align-center justify-center"
          >
            <v-btn
              small
              icon
              @click="forcePeriod(previewsMonthPeriodOutside)"
              dark
              class="ml-2"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn
              icon
              @click="forcePeriod(nextMonthPeriodOutside)"
              dark
              small
              class="ml-1"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-dialog
        scrollable
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="show_range_selector"
        max-width="1000"
      >
        <v-card class="">
          <v-card-title
            class="headline justify-center secondary darken-3 white--text"
            >{{ formatedEditingEventPeriod }}
            <v-spacer></v-spacer>
            <div
              v-if="isFullMonth"
              class="d-flex align-center justify-center"
              style="position: absolute; top: 13px; right: 50px"
            >
              <v-btn icon @click="setPeriod(previewsMonthPeriod)" dark
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <v-btn icon @click="setPeriod(nextMonthPeriod)" dark
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </div>
            <v-btn
              style="position: absolute; top: 13px; right: 10px"
              icon
              @click="show_range_selector = false"
              dark
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text class="white pb-0">
            <v-row>
              <v-col cols="4">
                <div class="grey lighten-4 rounded-lg pa-2">
                  <div class="px-2 pt-2">
                    <p
                      class="pb-0 mb-1 grey--text text--darken-1 font-weight-bold text-left"
                    >
                      {{ $t("period_proposals") }}:
                    </p>
                    <v-btn
                      small
                      v-for="(period, index) in next_available_periods"
                      :key="index"
                      :value="period.value"
                      @click="setPeriod(period.value)"
                      rounded
                      class="ma-1 "
                      elevation="0"
                      dark
                      outlined
                      :color="period.active ? 'accent' : 'grey'"
                      style="text-transform: none; font-weight:bold;font-size: 11px;"
                    >
                      <v-icon
                        class="mr-1"
                        small
                        dark
                        :color="
                          period.active
                            ? 'white'
                            : period.type === 'period'
                            ? 'secondary'
                            : getImpactColor(period.impact)
                        "
                        v-if="period.type && period.type !== 'period'"
                        >{{ getEventTypeIcon(period.type) }}</v-icon
                      >
                      {{ period.text }}
                    </v-btn>
                  </div>
                  <div v-if="previous_months" class="px-2"></div>

                  <div v-if="previous_months" class="px-2">
                    <p
                      class="pb-0 mt-3 mb-2 grey--text text--darken-1 font-weight-bold text-left"
                    >
                      {{ $t("previous_months") }}:
                    </p>
                    <v-select
                      :items="previous_months_selector"
                      item-text="text"
                      item-value="value"
                      :label="$t('select_month')"
                      @change="setPeriod($event)"
                      hide-details
                      flat
                      dense
                      solo
                      rounded
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No hay ningún mes disponible
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>

                  <!-- <v-divider></v-divider> -->
                  <div class="px-2">
                    <p
                      class="pb-0 mt-3 mb-2 grey--text text--darken-1 font-weight-bold  text-left"
                    >
                      {{ $t("featured_events") }}:
                    </p>
                    <v-autocomplete
                      :items="events"
                      item-text="name"
                      item-value="id"
                      hide-details
                      flat
                      dense
                      solo
                      rounded
                      background-color="white"
                      :label="$t('search_event')"
                      class="body mb-0"
                      clearable
                      v-model="event_selected"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No hay ningún evento con este texto
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar
                          :color="
                            item.type === 'period'
                              ? 'secondary'
                              : getImpactColor(item.impact)
                          "
                          class="text-h5 font-weight-light white--text"
                        >
                          <v-icon
                            v-if="item.type && item.type !== 'period'"
                            color="white"
                            >{{ getEventTypeIcon(item.type) }}</v-icon
                          >
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            getTitleDateFormat([item.start_date, item.end_date])
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <div class="pt-3 pb-2">
                      <v-btn
                        small
                        v-for="(period, index) in next_hotel_events"
                        :key="'event_' + index"
                        :value="period.value"
                        @click="setPeriod(period.value)"
                        rounded
                        class="ma-1 "
                        elevation="0"
                        dark
                        :color="
                          period.active ? getImpactColor(period.impact) : 'grey'
                        "
                        outlined
                        style="text-transform: none; font-weight:bold;font-size: 11px;"
                      >
                        <v-icon
                          class="mr-1"
                          small
                          dark
                          :color="
                            period.active
                              ? getImpactColor(period.impact)
                              : 'grey'
                          "
                          v-if="period.type && period.type !== 'period'"
                          >{{ getEventTypeIcon(period.type) }}</v-icon
                        >
                        {{ period.text }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <p class="mt-1 mb-5 ml-5">
                      {{ $t("select_period_or_event") }}:
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6">
                    <div id="calendar_prev">
                      <v-date-picker
                        v-model="edit_event_period"
                        :allowed-dates="eventPeriodAllowedDate"
                        range
                        first-day-of-week="1"
                        :show-adjacent-months="true"
                        :title-date-format="getTitleDateFormat"
                        no-title
                        :picker-date.sync="picker_date"
                      ></v-date-picker>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div id="calendar_next">
                      <v-date-picker
                        v-model="edit_event_period"
                        :allowed-dates="eventPeriodAllowedDate"
                        range
                        first-day-of-week="1"
                        :show-adjacent-months="true"
                        :title-date-format="getTitleDateFormat"
                        no-title
                        :picker-date.sync="picker_date2"
                      ></v-date-picker>
                    </div>
                  </v-col>
                </v-row>
                <v-card-actions class="pa-3">
                  <v-btn
                    class="px-8"
                    color="secondary"
                    rounded
                    :disabled="canUpdatePeriod"
                    @click="updatePeriod"
                  >
                    {{ $t("apply") }}
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="grey"
                    text
                    rounded
                    @click="show_range_selector = false"
                    >{{ $t("cancel") }}</v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:loading>
      <v-row>
        <v-col cols="12" md="3">
          <v-card height="90px">
            <v-skeleton-loader
              type="list-item-line"
              height="200"
              class="elevation-0"
            >
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
import * as Utils from "@/utils.js";

export default {
  name: "a-rms-range-selector",
  components: {
    aBaseComponent
  },
  props: {
    event_period: {
      type: Array,
      default: () => {
        return [
          Vue.moment().format("YYYY-MM-DD"),
          Vue.moment()
            .add(30, "days")
            .format("YYYY-MM-DD")
        ];
      }
    },
    max_days: {
      type: Number,
      default: 90
    },
    previous_months: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    title: "",
    current_visible_week_index: 0,
    item_height: 180,
    table_height: "calc(100% - 200px)",
    edit_event_period: [],
    show_range_selector: false,
    picker_date: null,
    picker_date2: null,
    event_selected: null,
    detail_days: [],
    show_details: false,
    show_details_mini: false
  }),
  watch: {
    picker_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        let d = Vue.moment(newVal + "-01").add(1, "month");
        this.picker_date2 = d.format("YYYY-MM");
      }
    },
    picker_date2(newVal, oldVal) {
      if (newVal !== oldVal) {
        let d = Vue.moment(newVal + "-01").subtract(1, "month");
        this.picker_date = d.format("YYYY-MM");
      }
    },
    event_selected(newVal) {
      if (newVal) {
        let event = this.events.find(e => e.id === newVal);
        this.setPeriod([event.start_date, event.end_date]);
      }
    },
    detail_days: {
      deep: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.show_details = true;
        } else {
          this.show_details = false;
        }
      }
    },
    current_hotel: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.id > 0) {
          this.getHotelEvents();
        }
      }
    }
  },
  methods: {
    getHotelEvents() {
      this.$store
        .dispatch("getHotelEvents", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment().format("YYYY")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setPeriod(v) {
      this.edit_event_period = JSON.parse(JSON.stringify(v));
      this.picker_date = Vue.moment(v[0]).format("YYYY-MM");
    },
    forcePeriod(v) {
      this.$emit("updatePeriod", v);
      this.setPeriod(v);
    },
    updatePeriod() {
      this.show_range_selector = false;
      this.$emit("updatePeriod", this.edit_event_period);
    },
    showRangeSelector() {
      this.show_range_selector = true;
      this.edit_event_period = JSON.parse(JSON.stringify(this.event_period));
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    getImpactColor(impact) {
      return this.calendar_store.impacts.find(i => i.value === impact).color;
    },
    eventPeriodAllowedDate(date) {
      return true;
    },
    getTitleDateFormat(dates) {
      return Utils.getFriendlyPeriod(dates[0], dates[1]);
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date);
    },
    ...mapActions([])
  },
  computed: {
    previewsMonthPeriod() {
      return [
        Vue.moment(this.edit_event_period[0])
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        Vue.moment(this.edit_event_period[0])
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD")
      ];
    },
    nextMonthPeriod() {
      return [
        Vue.moment(this.edit_event_period[0])
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        Vue.moment(this.edit_event_period[0])
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD")
      ];
    },
    previewsMonthPeriodOutside() {
      return [
        Vue.moment(this.event_period[0])
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        Vue.moment(this.event_period[0])
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD")
      ];
    },
    nextMonthPeriodOutside() {
      return [
        Vue.moment(this.event_period[0])
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        Vue.moment(this.event_period[0])
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD")
      ];
    },
    isFullMonthOutside() {
      let d_start = Vue.moment(this.event_period[0]);
      let d_end = Vue.moment(this.event_period[1]);

      return (
        d_start.isSame(d_end, "month") &&
        d_start.isSame(Vue.moment(d_end).startOf("month")) &&
        d_end.format("YYYY-MM-DD") ===
          Vue.moment(d_start)
            .endOf("month")
            .format("YYYY-MM-DD")
      );
    },
    isFullMonth() {
      let d_start = Vue.moment(this.edit_event_period[0]);
      let d_end = Vue.moment(this.edit_event_period[1]);

      return (
        d_start.isSame(d_end, "month") &&
        d_start.isSame(Vue.moment(d_end).startOf("month")) &&
        d_end.format("YYYY-MM-DD") ===
          Vue.moment(d_start)
            .endOf("month")
            .format("YYYY-MM-DD")
      );
    },
    canUpdatePeriod() {
      return (
        this.edit_event_period.length !== 2 ||
        (this.edit_event_period[0] === this.event_period[0] &&
          this.edit_event_period[1] === this.event_period[1])
      );
    },
    nextHotelEvents() {
      return Array.isArray(this.events)
        ? this.events
            .filter(
              e =>
                (e.impact === "high" ||
                  e.impact === "very_high" ||
                  e.impact === "exceptional") &&
                Vue.moment(e.start_date).isAfter(Vue.moment())
            )
            .slice(0, 5)
            .map(e => {
              return {
                type: e.type,
                impact: e.impact,
                text: e.name,
                value: [e.start_date, e.end_date]
              };
            })
        : [];
    },
    events() {
      return this.calendar_store.hotel_events;
    },
    next_hotel_events() {
      let periods = Array.isArray(this.events)
        ? this.events
            .filter(
              e =>
                (e.impact === "high" ||
                  e.impact === "very_high" ||
                  e.impact === "exceptional") &&
                Vue.moment(e.start_date).isAfter(Vue.moment())
            )
            .slice(0, 5)
            .map(e => {
              return {
                type: e.type,
                impact: e.impact,
                text: e.name,
                value: [e.start_date, e.end_date]
              };
            })
        : [];
      for (let period of periods) {
        period.active =
          period.value[0] === this.edit_event_period[0] &&
          period.value[1] === this.edit_event_period[1];
      }
      return periods;
    },
    next_available_periods() {
      let next_available_periods = [
        {
          text: "next_30_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(30, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_7_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(7, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_15_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(15, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "this_year",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("year")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("year")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "year-to-date",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("year")
              .format("YYYY-MM-DD"),
            Vue.moment().format("YYYY-MM-DD")
          ]
        },
        {
          text: "this_month",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_month",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        /* {
          text: "this_week",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("week")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("week")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_week",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(1, "week")
              .endOf("week")
              .format("YYYY-MM-DD")
          ]
        } */
        {
          text: "next_3_months",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(3, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        }
        /* {
          text: "next_6_months",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(6, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        } */
      ];
      let periods = next_available_periods;
      for (let period of periods) {
        period.text = this.$t(period.text);
        period.active =
          period.value[0] === this.edit_event_period[0] &&
          period.value[1] === this.edit_event_period[1];
      }
      return periods;
    },
    min_allowed_date() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    init_date() {
      return this.event_period[0];
    },
    end_date() {
      return this.event_period[1];
    },
    formatedEventPeriod() {
      let a = Utils.getFriendlyPeriod(
        this.event_period[0],
        this.event_period[1]
      );
      return a;
    },
    formatedEditingEventPeriod() {
      let a = Utils.getFriendlyPeriod(
        this.edit_event_period[0],
        this.edit_event_period[1]
      );
      return a;
    },
    previous_months_selector() {
      let items = [];
      if (!this.available_months) {
        return items;
      }
      let keys = Object.keys(this.available_months)
        .sort()
        .reverse();

      keys.forEach(key => {
        if (key <= Vue.moment().format("YYYY")) {
          items.push({
            header: key
          });
        }
        let months = this.available_months[key];
        //let months = JSON.parse(JSON.stringify(this.available_months[key]));
        months = months
          .sort(function(a, b) {
            return a - b;
          })
          .reverse();
        for (let i = 0; i < months.length; i++) {
          if (
            Vue.moment(key + "-" + months[i]) < Vue.moment().startOf("month")
          ) {
            items.push({
              text: Vue.moment(key + "-" + months[i]).format("MMMM"),
              value: [
                Vue.moment(key + "-" + months[i])
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                Vue.moment(key + "-" + months[i])
                  .endOf("month")
                  .format("YYYY-MM-DD")
              ]
            });
          }
        }
      });
      return items;
    },
    ...mapState({
      rms: state => state.rms,
      hotelsdot: state => state.hotelsdot,
      calendar_store: state => state.calendar,
      available_months: state => state.hotel.hotel_available_months
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days",
      available_years: "getHotelAvailableYears"
    })
  },
  mounted() {
    this.picker_date = Vue.moment(this.edit_event_period[0]).format("YYYY-MM");
  }
};
</script>
<style>
#calendar_prev .v-date-picker-header > .v-btn:nth-child(3) {
  visibility: hidden;
}
#calendar_next .v-date-picker-header > .v-btn:nth-child(1) {
  visibility: hidden;
}
</style>
